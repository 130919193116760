<template>
    <div>
        <!-- PAYMENT !-->
        <div class="d-flex flex-lg-row flex-column payment" v-if="product">
            <div class="d-flex flex-column payment__info" >
                <div class="d-flex justify-content-start align-items-center">
                    <a href="javascript:void(0)"  @click="$router.go(-1) " class="back-btn"><img src="../assets/icons/white-arrow-left.svg"></a>
                    <h4 class="m-0">{{$store.state.translation.cart_your_order}}</h4>        
                </div>
                <div class="payment__content">
                    <h2 class="mb-4">{{product.earthlings_product_family.name}}</h2>
                    <p class="subtype m-0">{{product.name}}</p>
                    <p class="size">{{product.short_description}}</p>
                </div>
                <div class="d-flex flex-column sum__prices">
                    <div class="d-flex justify-content-between">
                        <p>{{$store.state.translation.cart_product_price}}:</p>
                        <span>{{getPrice(product).fprice}}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <p>{{$store.state.translation.cart_delivery}}:</p>
                        <span>{{getPrice(product).fdelivery_price}}</span>
                    </div>
                    <div class="d-flex justify-content-between full-price">
                        <p>{{$store.state.translation.cart_summary}}:</p>
                        <span>{{getPrice(product).ftotal}}</span>
                    </div>
                </div>
            </div>
            <div class="payment__form">
                <div class="d-flex justify-content-start align-items-center">
                    <h4 class="m-0 text-uppercase p-2" v-if="state=='cart'">{{$store.state.translation.cart_go_to_payment}}</h4>        
                    <h4 class="m-0 text-uppercase p-2" v-if="state=='checkout'">{{$store.state.translation.cart_payment}}</h4>        
                </div>
                <div class="d-flex flex-column data__container">
                    <validation-observer ref="observer" v-slot="{ handleSubmit }" >
                    <form @submit.prevent="handleSubmit(onSubmit)" v-if="state == 'cart'">
                        <h5 class="text-uppercase p-2">{{$store.state.translation.cart_personal_data}}</h5>
                        <div class="d-flex justify-content-start flex-row radio-btn__container p-2">
                            <div>
                                <input v-model="form.billing_subject" type="radio" id="person" name="person_type" value="person" checked>
                                <label for="person">{{$store.state.translation.cart_private}}</label>
                            </div>
                            <div>
                                <input v-model="form.billing_subject" type="radio" id="firm" name="person_type" value="company">
                                <label for="firm">{{$store.state.translation.cart_company}}</label>
                            </div>
                        </div>
                        <div class="d-flex flex-row input__container flex-wrap">
                            <div>
                                <validation-provider
                                        :rules="{'required':true,'min': 2}"
                                        :name="`${$store.state.translation.el_form_lastname}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.last_name"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="last_name"
                                        />
                                        <label>{{$store.state.translation.el_form_lastname}}</label>
                                    </div>
                                
                                    <portal to="last_name">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("last_name", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                            </div>
                            <div>
                                <validation-provider
                                        :rules="{'required':true,'min': 2}"
                                        :name="`${$store.state.translation.el_form_firstname}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.first_name"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="first_name"
                                        />
                                        <label>{{$store.state.translation.el_form_firstname}}</label>
                                    </div>
                                
                                    <portal to="first_name">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("first_name", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                            </div>
                            
                             <div>
                                <validation-provider
                                        rules="required|email"
                                        :name="`${$store.state.translation.el_form_email}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.email"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="email"
                                        />
                                        <label>{{$store.state.translation.el_form_email}}</label>
                                    </div>
                                
                                    <portal to="email">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("email", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                                
                            </div>
                            <div>
                                <validation-provider
                                        rules="required"
                                        :name="`${$store.state.translation.el_form_phone}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.phone"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="phone"
                                        />
                                        <label>{{$store.state.translation.el_form_phone}}</label>
                                    </div>
                                
                                    <portal to="phone">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("phone", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                                
                            </div>
                        </div>
                        <div class="pt-5 pb-3">
                            <h5 class="text-uppercase p-2">{{$store.state.translation.cart_delivery_title}}</h5>
                        </div>
                        <div class="d-flex flex-row input__container flex-wrap">
                            <div>
                                <validation-provider
                                    rules="required"
                                    :name="`${$store.state.translation.el_form_postcode}`"
                                    v-slot="{ failedRules, classes}"
                                >
                                    <div class="isValidate" :class="classes">
                                        <select v-model="form.shipping_country_id" type="text" class="form-control" >
                                            <option v-for="pp in product.earthlings_product_price" :key="pp.id" :value="pp.country_id">{{pp.country.name}}</option>
                                        </select>
                                        <label>{{$store.state.translation.el_form_country}}</label>
                                    </div>
                                    <portal to="shipping_country_id">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("shipping_country_id", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                            </div>
                           <div>
                                <validation-provider
                                        rules="required"
                                        :name="`${$store.state.translation.el_form_postcode}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.shipping_postcode"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="shipping_postcode"
                                        />
                                        <label>{{$store.state.translation.el_form_postcode}}</label>
                                    </div>
                                
                                    <portal to="shipping_postcode">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("shipping_postcode", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                                
                            </div>
                            <div>
                                <validation-provider
                                        rules="required"
                                        :name="`${$store.state.translation.el_form_city}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.shipping_city"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="shipping_city"
                                        />
                                        <label>{{$store.state.translation.el_form_city}}</label>
                                    </div>
                                
                                    <portal to="shipping_city">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("shipping_city", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                               
                            </div>
                            <div>
                                <validation-provider
                                        rules="required"
                                        :name="`${$store.state.translation.el_form_city}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.shipping_address"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="shipping_address"
                                        />
                                        <label>{{$store.state.translation.el_form_address}}</label>
                                    </div>
                                
                                    <portal to="shipping_address">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("shipping_address", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                                
                            </div>
                        </div>
                        <div class="pt-5 pb-3">
                            <h5 class="text-uppercase p-2">{{$store.state.translation.cart_accounting}}</h5>
                            <div class="d-flex justify-content-start flex-row radio-btn__container p-2">
                                <div>
                                    <input type="radio"  :checked="form.same_billing_address == 1" @changed="(form.same_billing_address) ? form.same_billing_address = 0: form.same_billing_address=1">
                                    <label for="billing" @click="(form.same_billing_address) ? form.same_billing_address = 0: form.same_billing_address=1">{{$store.state.translation.cart_same_address}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row input__container flex-wrap mb-2" v-if="form.billing_subject =='company'">
                            <div>
                                <validation-provider 
                                        rules="required"
                                        :name="`${$store.state.translation.cart_billing_company__name}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.billing_company_name"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="billing_company_name"
                                        />
                                        <label>{{$store.state.translation.cart_billing_company_name}}</label>
                                    </div>
                                
                                    <portal to="billing_company_name">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("billing_company_name", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                                
                            </div>
                            <div >
                                <validation-provider
                                        rules="required"
                                        :name="`${$store.state.translation.cart_billing_tax_number}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.billing_tax_number"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="billing_tax_number"
                                        />
                                        <label>{{$store.state.translation.cart_billing_tax_number}}</label>
                                    </div>
                                
                                    <portal to="billing_tax_number">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("billing_tax_number", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                                
                            </div>
                        </div>
                         <div class="d-flex flex-row input__container flex-wrap" v-if="form.same_billing_address == 0">
                            
                            
                            
                            <div>
                                 <validation-provider
                                        rules="required"
                                        :name="`${$store.state.translation.shipping_country_id}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                <select disabled v-model="form.shipping_country_id" type="text" class="form-control" >
                                    <option v-for="pp in product.earthlings_product_price" :key="pp.id" :value="pp.country_id">{{pp.country.name}}</option>
                                </select>
                                <label>{{$store.state.translation.el_form_country}}</label>
                                </div>
                                
                                    <portal to="billing_postcode">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("shipping_country_id", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                            </div>
                           <div>
                                <validation-provider
                                        rules="required"
                                        :name="`${$store.state.translation.el_form_postcode}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.billing_postcode"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="billing_postcode"
                                        />
                                        <label>{{$store.state.translation.el_form_postcode}}</label>
                                    </div>
                                
                                    <portal to="billing_postcode">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("billing_postcode", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                                
                            </div>
                            <div>
                                <validation-provider
                                        rules="required"
                                        :name="`${$store.state.translation.el_form_city}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.billing_city"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="billing_city"
                                        />
                                        <label>{{$store.state.translation.el_form_city}}</label>
                                    </div>
                                
                                    <portal to="billing_city">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("billing_city", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                            
                            </div>
                            <div>
                                <validation-provider
                                        rules="required"
                                        :name="`${$store.state.translation.el_form_city}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input
                                            v-model="form.billing_address"
                                            class="form-control"
                                            type="text"
                                            aria-describedby="input-live-help input-live-1-feedback"
                                            
                                            id="billing_address"
                                        />
                                        <label>{{$store.state.translation.el_form_address}}</label>
                                    </div>
                                
                                    <portal to="billing_address">
                                        <div class="error">
                                            <span
                                                v-show="Object.entries(failedRules)[0]">{{
                                                getErrorMessage("billing_address", Object.entries(failedRules)[0])
                                            }}</span>
                                        </div>
                                    </portal>
                                </validation-provider>
                                
                            </div>
                        </div>
                        <div class="pt-5 pb-3">
                            <div class="d-flex justify-content-start flex-row radio-btn__container p-2">
                                <div>
                                     <validation-provider
                                        :rules="{ required: { allowFalse: false} }"
                                        :name="`${$store.state.translation.el_form_city}`"
                                        v-slot="{ failedRules, classes}"
                                    >
                                    <div class="isValidate" :class="classes">
                                        <input type="radio" v-model="aszf" :checked="aszf" :value="true">
                                        <label  for="consent" @click="(aszf) ?aszf = false: aszf=true" >{{$store.state.translation.cart_accept_aszf}}</label>
                                    </div>
                                     </validation-provider>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center pb-5">
                               
                                <button type="submit" class="next-btn" >
                                    {{$store.state.translation.cart_go_to_payment}}
                                </button>
                            </div>
                        </div>
                    </form>
                    </validation-observer>
                    <form id="payment-form" class="p-5" v-if="state == 'checkout'" :style="{opacity: (paymentinit) ? 0 : 1 }">
                                    <div id="payment-element">
                                        <!--Stripe.js injects the Payment Element-->
                                    </div>
                                    <!-- PAY BUTTON -->
                                    <button id="submit" class="btn mt-3 btn-green-600" >
                                        <div class="spinner hidden" id="spinner"></div>
                                        <span id="button-text" >{{ $store.state.translation.pay_now}}</span>
                                    </button>
                                    <!--<button type="button" @click="state = 'prepare'; checkOrderEnabled()" class="btn mt-3 ">
                                        <div class="spinner hidden" id="spinner"></div>
                                        <span id="button-text" >{{tr('btn_cancel')}}</span>
                                    </button>-->
                                    <div id="payment-message" class="hidden form-alert text-red-900 mb-0 mt-1"></div>
                                </form>
                                <p :style="{opacity: (paymentinit) ? 1 : 0 }">{{$store.state.translation.payment_coming_soon}}</p>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate/dist/vee-validate.full';
import {setInteractionMode} from "vee-validate";
import errorMessages from "@/assets/errorMessages.json";
import {required, email} from "vee-validate/dist/rules";

setInteractionMode("eager");
// Add a validation rules
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: 'At least 3 characters are required.'
});
extend("email", {
  ...email,
  message: 'Field Email Address must have a valid form.'
});
extend("required", {
  ...required,
  message: '{_field_} is required.'
});

export default {
    watch:{
        'form.shipping_country_id'(){
            this.selectCountry(this.form.shipping_country_id);
        }
    },
    data(){
        return {
            paymentinit: false,
            state : 'cart',
            orderId : undefined,
            formSubmitted: false,
            sendingError: false,
            aszf: false,
            product: false,
            form:{
                last_name : "",
                first_name: "",
                billing_subject: "person",
                same_billing_address: 1,
                shipping_country_id: this.$store.state.selectedCountry.id

            }
        }
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        onSubmit() {
            this.form.shipping_name = this.form.first_name + " " + this.form.last_name;
            if (parseInt(this.form.same_billing_address) == 1){
                this.form.billing_address = this.form.shipping_address;
                this.form.billing_city = this.form.shipping_city;
                this.form.billing_name = this.form.shipping_name;
                this.form.billing_postcode = this.form.shipping_postcode;
            }
            this.form.products = [{product_id: this.product.id, quantity: 1}]
            this.form.billing_country_id = this.form.shipping_country_id;
            this.post('ordercontroller/start',this.form).then((res)=>{
                    this.orderID = res.data.to_client.order_id;
                    this.state = 'checkout'
                    this.$nextTick().then(()=>{
                        this.orderSending = false;
                        this.initStripe(res.data.to_client.public_api_key,res.data.to_client.client_secret,res.data.to_client.amount,res.data.to_client.currency);
                    })
            })
        },
         getErrorMessage(fieldName, violation) {
            return violation ? (errorMessages[this.$store.state.language_id][fieldName] && errorMessages[this.$store.state.language_id][fieldName][violation[0]]) ?? violation[1] : undefined;
        },   
        getProduct(){
            if (this.$store.state.selectedProduct == null)
            {
                if (localStorage.getItem('cart') != null)
                {
                    this.$store.state.selectedProduct = JSON.parse(localStorage.getItem('cart'));
                }
                else
                {
                    this.$router.push('/');
                    return;
                }
            }
            
            this.get('earthlings_product/'+this.$store.state.selectedProduct.id,{
                params:{relations:1,
                relations_params:{
                    earthlings_product_price: {
                        relations:1,
                        relations_params:{
                            country: {
                                relations:1
                            }
                        }
                    }
                    
                    }}}).then((res=>{
                this.product = res.data.earthlings_product[0];
            }))
        },
        async selectCountry(cid){
        
            await this.get('country/'+cid,{params:{relations: 1}}).then(res=>{

                this.$store.state.selectedCountry = res.data.country[0];
                console.log(this.$store.state.selectedCountry)
                localStorage.setItem('country',JSON.stringify(this.$store.state.selectedCountry))
                this.getProduct();
            })
        },
        initStripe(public_api_key,clientSecret,amount,currency){
            window['vueinstance'] = this;
            window.selectedLangCode = this.selectedLangCode;
            window.order_id = this.orderID
            const stripe = window.Stripe(public_api_key);

            let elements;
           

            initialize();
            checkStatus();

            document
            .querySelector("#payment-form")
            .addEventListener("submit", handleSubmit);

            // Fetches a payment intent and captures the client secret
            async function initialize() {
           
            

            const appearance = {
                    theme: 'stripe',
                    variables: {
                    colorPrimary: '#05e11e',
                    },
                };
                elements = stripe.elements({ appearance, clientSecret });
                
                var paymentRequest = stripe.paymentRequest({
                    country: 'HU',
                    currency: currency,
                    total: {
                        label: 'Total',
                        amount: amount,
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,
                });

           
            
                const paymentElement = elements.create("payment");
                paymentElement.mount("#payment-element");

                var prButton = elements.create('paymentRequestButton', {
                    paymentRequest: paymentRequest,
                });
                setTimeout(function(){
                    window['vueinstance'].paymentinit = false;
                },2000)
                    // Check the availability of the Payment Request API first.
                paymentRequest.canMakePayment().then(function(result) {
                    console.log(result);
                    if (result) {
                        prButton.mount('#payment-request-button');
                    } else {
                        if ( document.getElementById('payment-request-button'))
                            document.getElementById('payment-request-button').style.display = 'none';
                    }
                    
                });

            }

            async function handleSubmit(e) {
                e.preventDefault();
                setLoading(true);
            
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url:window.location.protocol + "//"+window.location.host +"/" +  window.selectedLangCode + "/payment_complete/"+window.order_id,
                    },
                });

                // This point will only be reached if there is an immediate error when
                // confirming the payment. Otherwise, your customer will be redirected to
                // your `return_url`. For some payment methods like iDEAL, your customer will
                // be redirected to an intermediate site first to authorize the payment, then
                // redirected to the `return_url`.
                if (error.type === "card_error" || error.type === "validation_error") {
                    showMessage(error.message);
                } else {
                    showMessage("An unexpected error occured.");
                }

                setLoading(false);
            }

            // Fetches the payment intent status after payment submission
            async function checkStatus() {
            const clientSecret = new URLSearchParams(window.location.search).get(
                "payment_intent_client_secret"
            );

            if (!clientSecret) {
                return;
            }

            const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

            switch (paymentIntent.status) {
                case "succeeded":
                showMessage("Payment succeeded!");
                break;
                case "processing":
                showMessage("Your payment is processing.");
                break;
                case "requires_payment_method":
                showMessage("Your payment was not successful, please try again.");
                break;
                default:
                showMessage("Something went wrong.");
                break;
            }
            }

            // ------- UI helpers -------

            function showMessage(messageText) {
            const messageContainer = document.querySelector("#payment-message");

            messageContainer.classList.remove("hidden");
            messageContainer.textContent = messageText;

            setTimeout(function () {
                messageContainer.classList.add("hidden");
                messageText.textContent = "";
            }, 4000);
            }

            // Show a spinner on payment submission
            function setLoading(isLoading) {
            if (isLoading) {
                // Disable the button and show a spinner
                document.querySelector("#submit").disabled = true;
                document.querySelector("#spinner").classList.remove("hidden");
                document.querySelector("#button-text").classList.add("hidden");
            } else {
                document.querySelector("#submit").disabled = false;
                document.querySelector("#spinner").classList.add("hidden");
                document.querySelector("#button-text").classList.remove("hidden");
            }
            }
             
        }
    },
    mounted(){
        let stripe = document.createElement('script')
      stripe.setAttribute('src', 'https://js.stripe.com/v3/')
      document.head.appendChild(stripe)
    },
    created(){
        this.getProduct()
    }
}
</script>